import { useNavigate } from 'react-router-dom';

import Spacing from '../component/Spacing';
import Text from '../component/Text';
import c from '../util/c';

const Complete = () => {
  const navigate = useNavigate();
  return (
    <div className={c('flex flex-col items-center', 'h-[100vh] w-full', 'px-[16px]', 'relative')}>
      <div className={c('h-[calc(100%-74px)]', 'flex flex-col items-center justify-center')}>
        <CheckIcon />
        <Spacing height='h-[31px]' />
        <Text
          size={20}
          weight='Bold'
          lineHeight='leading-[26px]'
          color='text-black'
          whiteSpace='preWrap'
          align='center'
        >
          {`환부사진 접수가\n완료되었습니다`}
        </Text>
      </div>
      <div
        className={c('absolute bottom-0 left-0', 'px-[16px] pb-[20px]', 'w-full', 'bg-[#ffffff]')}
      >
        <button
          className={c(
            'h-[54px] w-full',
            'flex items-center justify-center',
            'font-Pretendard-SemiBold text-[16px] leading-[30px] text-white',
            'rounded-[8px] bg-blue_main_2',
          )}
          onClick={() => navigate('/')}
        >
          확인
        </button>
      </div>
    </div>
  );
};
export default Complete;

const CheckIcon = () => (
  <div
    className={c(
      'h-[66px] w-[66px]',
      'rounded-full',
      'bg-blue_main_2',
      'flex items-center justify-center',
    )}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='22'
      viewBox='0 0 28 22'
      fill='none'
    >
      <path
        d='M25 3.66797L10.3333 18.3346L3 11.0013'
        stroke='white'
        stroke-width='5.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  </div>
);
